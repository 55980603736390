import { BookingStatus } from '@/generated-types/graphql.types';
var BookingStatusExtend;
(function (BookingStatusExtend) {
    BookingStatusExtend["Confirmed"] = "CONFIRMED";
    BookingStatusExtend["Unpaid"] = "UNPAID";
})(BookingStatusExtend || (BookingStatusExtend = {}));
export const ExtendedBookingStatus = {
    ...BookingStatusExtend,
    ...BookingStatus
};
