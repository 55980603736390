import { groupBy, maxBy, minBy, sortBy } from 'lodash';
import { BookingStatus, BookingType, OrderItemType, OrderStateType } from '@/generated-types/graphql.types';
import { dateString, isAfterCheck, unixFormatted, unixNow } from '@/util/dates';
import { reFormatTime } from '@/util/utils';
import { ExtendedBookingStatus } from '@/Booking/booking.types';
import { BookingTypes, BookingStates, TimelineActions } from '@/Booking/booking.const';
import useUserRoles from '@/shared/composables/useUserRoles';
export var BOOKING_BADGE_STATUSES;
(function (BOOKING_BADGE_STATUSES) {
    BOOKING_BADGE_STATUSES["UPCOMING"] = "UPCOMING";
    BOOKING_BADGE_STATUSES["REQUEST"] = "REQUEST";
    BOOKING_BADGE_STATUSES["CLOSED"] = "CLOSED";
    // TODO: Define condition for these types.
    BOOKING_BADGE_STATUSES["RESERVED_BY_OFFER"] = "RESERVED_BY_OFFER";
    BOOKING_BADGE_STATUSES["RESERVED_BY_CUSTOMER"] = "RESERVED_BY_CUSTOMER";
    BOOKING_BADGE_STATUSES["RESERVED"] = "RESERVED";
})(BOOKING_BADGE_STATUSES || (BOOKING_BADGE_STATUSES = {}));
export const mapBookings = (data) => {
    const bookingsByStatus = groupBy(data, booking => booking?.booking_status);
    const bookingsByType = {
        [BookingTypes.REQUESTS]: new Array().concat(bookingsByStatus.PENDING?.filter(booking => booking?.order?.order_state === OrderStateType.HostApproval ||
            booking?.order?.order_state === OrderStateType.CustomerApproval) ?? []),
        [BookingTypes.UPCOMING]: new Array()
            .concat(bookingsByStatus.PAID?.filter(booking => booking.slot_end && isAfterCheck(booking?.slot_end)) ?? [])
            .concat(bookingsByStatus.PENDING?.filter(booking => booking?.is_afterpay_requested) ?? []),
        [BookingTypes.RESERVATIONS]: new Array().concat(bookingsByStatus.PENDING?.filter(booking => (booking?.order?.order_state === OrderStateType.PaymentPending ||
            booking?.order?.order_state === OrderStateType.PaymentFailed) &&
            !booking?.is_afterpay_requested) ?? []),
        [BookingTypes.ARCHIVED]: new Array().concat(bookingsByStatus.PASSED ?? [], bookingsByStatus.EXPIRED ?? [], bookingsByStatus.CANCELLED ?? [], bookingsByStatus.DECLINED ?? [])
    };
    return bookingsByType;
};
export const getBookingsSummaryData = (bookingsByType) => Object.entries(bookingsByType)
    .filter(([key]) => key !== BookingTypes.ARCHIVED)
    .map(([key, value]) => ({
    bookingsType: key,
    bookingsCount: value.length
}));
export const groupBookingsByDate = (bookingsByType) => {
    const bookingsByDate = Object.entries(bookingsByType).map(([key, value]) => ({
        status: key,
        byDate: groupBy(value.sort((a, b) => a?.slot_start && b?.slot_start ? b?.slot_start - a?.slot_start : 0), booking => booking?.slot_start && unixFormatted(booking?.slot_start, 'ddd, D MMM'))
    }));
    return Object.assign({}, ...bookingsByDate.map(booking => ({ [booking.status]: booking.byDate })));
};
export const getFacilities = (services) => {
    return (services &&
        groupBy(services, service => service?.service_type));
};
export const getBookingStatus = (booking) => {
    const state = {
        [OrderStateType.HostApproval]: ExtendedBookingStatus.Pending,
        [OrderStateType.CustomerApproval]: ExtendedBookingStatus.Pending,
        [OrderStateType.PaymentPending]: booking?.is_afterpay_requested
            ? ExtendedBookingStatus.Confirmed
            : ExtendedBookingStatus.Unpaid,
        [OrderStateType.Paid]: booking?.booking_meta?.is_prospect_booking
            ? ExtendedBookingStatus.Confirmed
            : ExtendedBookingStatus.Paid,
        [OrderStateType.PaymentFailed]: ExtendedBookingStatus.Unpaid,
        [OrderStateType.PaymentProcessing]: ExtendedBookingStatus.Unpaid
    };
    const statuses = Object.values(BookingStatus).reduce((acc, item) => ({ ...acc, [item]: item }), {});
    statuses[BookingStatus.Pending] = booking?.order?.order_state
        ? state[booking?.order?.order_state]
        : BookingStatus.Pending;
    statuses[BookingStatus.Paid] = booking?.order?.order_state
        ? state[booking?.order?.order_state]
        : BookingStatus.Paid;
    return booking?.booking_status && statuses[booking?.booking_status];
};
export const getBookingRefund = (booking) => {
    const priceDifference = booking?.order?.order_total - booking?.origin.order.order_total;
    if (priceDifference > 0) {
        return {
            label: 'extra_payment',
            amount: priceDifference.toFixed(2)
        };
    }
    else if (priceDifference < 0) {
        return {
            label: 'refund',
            amount: Math.abs(priceDifference).toFixed(2)
        };
    }
    else {
        return {
            label: 'no_payment',
            amount: null
        };
    }
};
export const getBookingState = (booking) => {
    const orderStates = {
        [OrderStateType.HostApproval]: BookingStates.WAITING_FOR_HOST,
        [OrderStateType.CustomerApproval]: BookingStates.WAITING_FOR_CUSTOMER,
        [OrderStateType.PaymentPending]: BookingStates.UNPAID,
        [OrderStateType.PaymentFailed]: BookingStates.UNPAID,
        [OrderStateType.ClosedByCustomer]: BookingStates.CANCELLED_BY_CUSTOMER,
        [OrderStateType.ClosedByHost]: BookingStates.CANCELLED_BY_HOST,
        [OrderStateType.Paid]: BookingStates.PAID
    };
    const bookingStates = {
        [BookingStatus.Pending]: booking?.order?.order_state && orderStates[booking?.order?.order_state],
        [BookingStatus.Cancelled]: booking?.order?.order_state && orderStates[booking?.order?.order_state],
        [BookingStatus.Draft]: BookingStates.DRAFT,
        [BookingStatus.Expired]: BookingStates.EXPIRED,
        [BookingStatus.Declined]: BookingStates.DECLINED,
        [BookingStatus.Paid]: booking?.order?.order_state && orderStates[booking?.order?.order_state],
        [BookingStatus.Passed]: BookingStates.PASSED
    };
    return booking?.booking_status && bookingStates[booking?.booking_status];
};
export const getBookingTimeline = (booking, isVendor) => {
    const bookingState = getBookingState(booking);
    const isPostPaymentBooking = booking?.is_afterpay_requested;
    const reservationDeadline = booking?.ts_reservation_end;
    const customerApprovalDeadline = booking?.order?.ts_customer_approval_dl;
    const paymentDeadline = booking.is_afterpay_requested && isVendor
        ? booking?.order?.ts_payment_expiration_dl
        : booking?.ts_reservation_end;
    const hostApprovalDeadline = booking?.order?.ts_host_approval_dl;
    const totalPaid = !!booking?.order?.total_paid;
    const bookingStart = booking?.slot_start;
    const roles = useUserRoles();
    const canBeEdited = roles.value.BOOKER
        ? booking?.order?.ts_order_cancellation_dl
        : false;
    // The maximum date when the booking can be cancelled with a refund
    const cancellationDeadline = booking?.order?.ts_order_cancellation_dl;
    // If the user is customer, the cancellation date has not been passed,
    // the booking has been paid and the event has not been started yet
    // Cancellation is allowed with full refund
    const customerCancelWithRefund = !isVendor &&
        cancellationDeadline &&
        bookingStart &&
        unixNow < cancellationDeadline &&
        totalPaid &&
        unixNow < bookingStart
        ? cancellationDeadline
        : false;
    // If the user is customer and the cancellation date has already passed or the event has started,
    // or booking is not paid
    // Cancellation is allowed without refund
    const customerCancelWithoutRefund = !isVendor &&
        bookingStart &&
        cancellationDeadline &&
        bookingStart &&
        (unixNow >= cancellationDeadline || unixNow >= bookingStart || !totalPaid)
        ? cancellationDeadline
        : false;
    // If the user is vendor and the event hasn't been started yet and the order isn't paid
    // Cancellation is allowed.
    const vendorCancel = isVendor &&
        cancellationDeadline &&
        bookingStart &&
        unixNow < bookingStart &&
        !totalPaid;
    // If the user is vendor and the event hasn't been started yet
    // Cancellation is allowed with full refund.
    const vendorCancelWithRefund = isVendor &&
        cancellationDeadline &&
        bookingStart &&
        unixNow < bookingStart &&
        totalPaid;
    switch (bookingState) {
        case BookingStates.UNPAID: {
            return {
                [TimelineActions.RESERVED]: isVendor &&
                    reservationDeadline &&
                    reservationDeadline > unixNow &&
                    reservationDeadline &&
                    !isPostPaymentBooking
                    ? reservationDeadline
                    : false,
                [TimelineActions.PAY]: !isVendor &&
                    paymentDeadline &&
                    paymentDeadline > unixNow &&
                    paymentDeadline &&
                    !isPostPaymentBooking
                    ? paymentDeadline
                    : false,
                [TimelineActions.EDIT_BOOKING]: canBeEdited,
                [TimelineActions.USER_INFO]: isVendor || booking?.booking_type === BookingType.Request,
                [TimelineActions.CUSTOMER_CANCEL_WITH_REFUND]: customerCancelWithRefund,
                [TimelineActions.CUSTOMER_CANCEL_WITHOUT_REFUND]: customerCancelWithoutRefund,
                [TimelineActions.VENDOR_CANCEL]: vendorCancel,
                [TimelineActions.VENDOR_CANCEL_WITH_REFUND]: vendorCancelWithRefund,
                [TimelineActions.POST_PAYMENT_FOR_BOOKER]: !isVendor && isPostPaymentBooking,
                [TimelineActions.POST_PAYMENT_FOR_VENDOR]: isVendor && isPostPaymentBooking,
                [TimelineActions.VENDOR_POST_PAYMENT_INVOICING]: isVendor && isPostPaymentBooking
            };
        }
        case BookingStates.WAITING_FOR_HOST: {
            return {
                [TimelineActions.BOOKING_REQUEST_GUEST_VIEW]: !isVendor &&
                    hostApprovalDeadline &&
                    hostApprovalDeadline > unixNow &&
                    hostApprovalDeadline
                    ? hostApprovalDeadline
                    : false,
                [TimelineActions.BOOKING_REQUEST_VENDOR_VIEW]: isVendor &&
                    hostApprovalDeadline &&
                    hostApprovalDeadline > unixNow &&
                    hostApprovalDeadline
                    ? hostApprovalDeadline
                    : false,
                [TimelineActions.EDIT_BOOKING]: canBeEdited,
                [TimelineActions.RESERVED]: isVendor &&
                    reservationDeadline &&
                    reservationDeadline > unixNow &&
                    reservationDeadline,
                [TimelineActions.USER_INFO]: isVendor,
                [TimelineActions.CUSTOMER_CANCEL_WITH_REFUND]: customerCancelWithRefund,
                [TimelineActions.CUSTOMER_CANCEL_WITHOUT_REFUND]: customerCancelWithoutRefund,
                [TimelineActions.VENDOR_CANCEL]: vendorCancel,
                [TimelineActions.VENDOR_CANCEL_WITH_REFUND]: vendorCancelWithRefund
            };
        }
        case BookingStates.WAITING_FOR_CUSTOMER: {
            return {
                [TimelineActions.BOOKING_REQUEST_GUEST_VIEW]: !isVendor &&
                    customerApprovalDeadline &&
                    customerApprovalDeadline > unixNow &&
                    customerApprovalDeadline,
                [TimelineActions.USER_INFO]: true,
                [TimelineActions.EDIT_BOOKING]: canBeEdited,
                [TimelineActions.CUSTOMER_CANCEL_WITH_REFUND]: customerCancelWithRefund,
                [TimelineActions.CUSTOMER_CANCEL_WITHOUT_REFUND]: customerCancelWithoutRefund,
                [TimelineActions.VENDOR_CANCEL]: vendorCancel,
                [TimelineActions.VENDOR_CANCEL_WITH_REFUND]: vendorCancelWithRefund
            };
        }
        case BookingStates.PAID: {
            return {
                [TimelineActions.USER_INFO]: true,
                [TimelineActions.FINANCE]: (totalPaid && !isVendor && !isPostPaymentBooking) ||
                    (totalPaid && isVendor && isPostPaymentBooking) ||
                    (totalPaid && isVendor && !isPostPaymentBooking),
                [TimelineActions.EDIT_BOOKING]: canBeEdited,
                [TimelineActions.CUSTOMER_CANCEL_WITH_REFUND]: customerCancelWithRefund && !isPostPaymentBooking
                    ? customerCancelWithRefund
                    : false,
                [TimelineActions.CUSTOMER_CANCEL_WITHOUT_REFUND]: customerCancelWithoutRefund ||
                    (customerCancelWithRefund && isPostPaymentBooking),
                [TimelineActions.VENDOR_CANCEL]: vendorCancel,
                [TimelineActions.VENDOR_CANCEL_WITH_REFUND]: vendorCancelWithRefund && !isPostPaymentBooking
            };
        }
        case BookingStates.CANCELLED_BY_CUSTOMER: {
            return {
                [TimelineActions.USER_INFO]: isVendor,
                [TimelineActions.FINANCE]: (totalPaid && !isVendor && !isPostPaymentBooking) ||
                    (totalPaid && isVendor && isPostPaymentBooking) ||
                    (totalPaid && isVendor && !isPostPaymentBooking)
            };
        }
        case BookingStates.CANCELLED_BY_HOST: {
            return {
                [TimelineActions.USER_INFO]: isVendor,
                [TimelineActions.FINANCE]: (totalPaid && !isVendor && !isPostPaymentBooking) ||
                    (totalPaid && isVendor && isPostPaymentBooking) ||
                    (totalPaid && isVendor && !isPostPaymentBooking)
            };
        }
        case BookingStates.EXPIRED: {
            return {
                [TimelineActions.EXPIRED]: !isVendor,
                [TimelineActions.USER_INFO]: isVendor
            };
        }
        case BookingStates.PASSED: {
            return {
                [TimelineActions.FINANCE]: (totalPaid && !isVendor && !isPostPaymentBooking) ||
                    (totalPaid && isVendor && isPostPaymentBooking) ||
                    (totalPaid && isVendor && !isPostPaymentBooking),
                [TimelineActions.PAY]: isVendor &&
                    isPostPaymentBooking &&
                    paymentDeadline &&
                    unixNow <= paymentDeadline &&
                    !totalPaid
                    ? paymentDeadline
                    : false,
                [TimelineActions.OVERDUE_PAYMENT]: isVendor &&
                    isPostPaymentBooking &&
                    paymentDeadline &&
                    !totalPaid &&
                    unixNow > paymentDeadline
                    ? paymentDeadline
                    : false,
                [TimelineActions.USER_INFO]: isVendor,
                [TimelineActions.PASSED]: (!isVendor && booking.booking_status === BookingStatus.Passed) ||
                    (isVendor &&
                        booking.booking_status === BookingStatus.Passed &&
                        totalPaid)
            };
        }
        case BookingStates.DECLINED: {
            return {
                [TimelineActions.USER_INFO]: isVendor
            };
        }
    }
};
export const generateLegacyBooking = (booking) => {
    return {
        attendees: booking?.attendees,
        // TODO: this code returns -1 hour from the actual start and end time, causes the booking to be created with wrong time
        // bookingStart: booking?.slot_start
        //   ? dateString(booking?.slot_start, globalThis.$timezone) : undefined,
        // bookingEnd: booking?.slot_end
        //   ? dateString(booking?.slot_end, globalThis.$timezone) : undefined,
        // TODO: Temporary fix for the above issue
        bookingStart: booking?.slot_start
            ? dateString(booking?.slot_start, globalThis.$timezone, true)
            : undefined,
        bookingEnd: booking?.slot_end
            ? dateString(booking?.slot_end, globalThis.$timezone, true)
            : undefined,
        offerValidityTime: booking?.offer_validity_time,
        isReservationExtended: booking?.is_reservation_extended,
        orderItems: booking?.order?.order_items?.map(item => ({
            orderItemDescription: item?.order_item_description,
            orderItemType: item?.order_item_type,
            price: item?.order_item_price,
            quantity: item?.quantity,
            referenceId: item?.reference_id
        })),
        spaceId: booking?.space?.space_id
    };
};
export const getBookingBadgeStatus = (bookingStatus, orderState) => {
    switch (true) {
        case bookingStatus === BookingStatus.Pending &&
            (orderState === OrderStateType.HostApproval ||
                orderState === OrderStateType.CustomerApproval):
            return BOOKING_BADGE_STATUSES.REQUEST;
        case bookingStatus === BookingStatus.Paid:
            return BOOKING_BADGE_STATUSES.UPCOMING;
        case bookingStatus === BookingStatus.Pending &&
            orderState === OrderStateType.PaymentPending:
            return BOOKING_BADGE_STATUSES.RESERVED_BY_CUSTOMER;
        default:
            return BOOKING_BADGE_STATUSES.CLOSED;
    }
};
export const bookingBadgeMeta = {
    UPCOMING: 'circle-check',
    REQUEST: 'circle-question',
    RESERVED_BY_OFFER: 'clipboard-alt',
    RESERVED_BY_CUSTOMER: 'clock',
    CLOSED: 'lock'
};
export const getBookingOptions = (booking) => {
    return booking?.order?.order_items?.reduce((acc, item) => {
        switch (item?.order_item_type) {
            case OrderItemType.Configuration: {
                const configuration = booking?.space?.space_configurations?.find(configuration => configuration?.configuration_id === item?.reference_id);
                return {
                    ...acc,
                    configurations: acc.configurations?.concat(configuration ? Object.assign({}, configuration, item) : [])
                };
            }
            case OrderItemType.Service: {
                delete item.order_item_id;
                return {
                    ...acc,
                    services: acc.services?.concat(item ?? [])
                };
            }
            case OrderItemType.Space: {
                return {
                    ...acc,
                    space: acc.space?.concat(item ?? [])
                };
            }
            case OrderItemType.Custom: {
                return {
                    ...acc,
                    customs: acc.customs?.concat(item ?? [])
                };
            }
            default:
                return acc;
        }
    }, {
        configurations: [],
        services: [],
        space: [],
        customs: []
    });
};
export const getFormattedSchedule = (venueWorkTime) => {
    if (venueWorkTime && venueWorkTime.length) {
        const minHour = minBy(venueWorkTime, e => e?.open_time).open_time;
        const maxHour = maxBy(venueWorkTime, e => e?.close_time).close_time;
        const labels = [];
        let i;
        i = minHour;
        if (maxHour) {
            while (i <= maxHour) {
                labels.push({ label: reFormatTime(i), value: i, width: '100%' });
                i += 30;
            }
        }
        return {
            open: minHour,
            close: maxHour,
            labels: sortBy(labels, ['value'])
        };
    }
    return {};
};
